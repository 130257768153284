export const SEARCH_FILTER_PARAMS_KEYS = {
  OTHER_FILTERS: 'other_filters',
  CONTEXTUAL_FILTERS: 'contextual_filters'
};

export const SEARCH_PAGE_PARAMS_KEYS = {
  PAGE: 'page',
  ROWS_PER_PAGE: 'rowsPerPage'
};

export const SORTING_PARAMS_KEYS = {
  SORTING: 'sorting'
};
