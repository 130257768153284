import { toast, type Id, type ToastOptions } from 'react-toastify';

const toastMeta: { id: null | Id } = { id: null };

export function errorToast(
  msg: string,
  options?: ToastOptions<{}> | undefined
) {
  dismissAllToast();
  toastMeta.id = toast.error(msg, options);
}

export function successToast(
  msg: string,
  options?: ToastOptions<{}> | undefined
) {
  dismissAllToast();
  toastMeta.id = toast.success(msg, options);
}

export function infoToast(msg: string, options?: ToastOptions<{}> | undefined) {
  dismissAllToast();
  toastMeta.id = toast(msg, options);
}

function dismissAllToast() {
  if (toastMeta.id) {
    toast.clearWaitingQueue();
    toast.dismiss(toastMeta.id);
  }
}
