import { apiCallBack } from '../ApiMethods';
import { InferenceStatusParams, InferenceStatusResponse } from './types';

import { encodeURL } from 'app/utils/helpers';

export const getWaferMapById = (params: ApiBase<{ id: number }>) => {
  const { id } = params.baseParams;

  return apiCallBack({
    method: 'GET',
    url: `/api/v1/classif-ai/wafer-map/${id}/`,
    ...params
  }).then(_ => _.data);
};

export const getInferenceStatus = (params: ApiBase<InferenceStatusParams>) => {
  return apiCallBack<InferenceStatusParams, unknown, InferenceStatusResponse>({
    method: 'GET',
    url: `/api/v1/classif-ai/file-set-inference-queue/progress_status/`,
    ...params,
    transformParams: baseParams => {
      const { ml_model_id__in, ...rest } = baseParams as object & {
        ml_model_id__in: number;
      };

      return { file_set_filters: encodeURL(rest), ml_model_id__in };
    }
  }).then(_ => _.data);
};
